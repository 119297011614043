import { from, RouteType } from './routes';

export type ProductListType = 'velo' | 'liste-attente' | 'consigne' | 'consigne-libre';

const home: RouteType = {
  path: '/',
};

const contact: RouteType = {
  path: '/contact',
};

const signIn: RouteType = {
  path: '/connexion',
};

const signUp: RouteType = {
  path: '/inscription',
};

const signOut: RouteType = {
  path: '/deconnexion',
};

const mailValidation: RouteType = {
  path: '/validation-email',
};

const forgottenPassword: RouteType = {
  path: '/mot-de-passe-oublie',
};

const changePwd: RouteType = {
  path: '/reset-mot-de-passe',
};

const myReservations: RouteType = {
  path: '/mes-reservations',
};

const myAccount: RouteType = {
  path: '/mes-informations',
};

const myInvoices: RouteType = {
  path: '/mes-factures',
};

const repairAndOverhaul: RouteType = {
  path: '/reparation-et-revision',
};

const workshop: RouteType = {
  path: '/probleme-avec-mon-velo',
};

export type RootResaProductRouteParams = {
  type: ProductListType;
};

const rentBikeRoot: RouteType<RootResaProductRouteParams> = {
  path: '/:type',
};

const resaBike: RouteType = from(rentBikeRoot, {
  path: '',
});

export type ResaProductTunnelRouteParams = {
  articleID: string;
  pdl: string;
};

export type ResaProductPricesRouteParams = { pdl: string; articleType: string };

const resaBikePrices = from<RootResaProductRouteParams, ResaProductPricesRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleType/choix-tarif',
});

const resaBikeAccessories = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/accessoires',
});

const resaBikeForm = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/mes-informations',
});

const resaValidation = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/validation',
});

const rentBike = Object.assign(rentBikeRoot, {
  children: { resaBike, resaBikePrices, resaBikeForm, resaBikeAccessories, resaValidation },
});

const frontRoutes = {
  home,
  contact,
  signIn,
  signOut,
  signUp,
  mailValidation,
  forgottenPassword,
  changePwd,
  myReservations,
  myAccount,
  workshop,
  myInvoices,
  repairAndOverhaul,
  rentBike,
};

export default frontRoutes;
