import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useLocation } from 'react-router-dom';
import useClickOutside from '../../internal/hooks/useClickOutside';
import { DropdownIcon } from '../../const/icons';

type Props = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'children'> & {
  children: (open: boolean) => React.ReactNode;
  title: string;
  buttonClassName?: string;
};

function remToPx(value: number) {
  return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const Dropdown: React.FC<Props> = ({ children, title, className, buttonClassName }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, remToPx(0.5)],
        },
      },
    ],
  });

  const [open, setOpen] = useState(false);

  useClickOutside(dropdownRef.current, () => setOpen(false));

  const onClick = () => setOpen(!open);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div ref={dropdownRef} className={className}>
      <button
        className={classNames('font-bold text-xs flex items-center', buttonClassName)}
        ref={setReferenceElement}
        onClick={onClick}
      >
        {title}
        <DropdownIcon className={'mb-1 ml-2'} width={11} />
      </button>

      {open && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {children(open)}
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  );
};

export default Dropdown;
