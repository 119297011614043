import ScrollToTop from 'fragments/scrollToTop/ScrollToTop';
import Splash from 'internal/components/Splash';
import ThemeProvider from 'internal/providers/ThemeProvider';
import React, { ReactElement, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from 'router/Router';
import Snackbar from '../../components/Snackbar/Snackbar';
import withConfig from '../../hoc/withConfig';
import useFetchReservation from '../../hook/useFetchReservation';
import { selectCollName } from '../selectors/remoteConfigSelectors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function withQueryClientProvider<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithQueryClient = (props: T) => {
    const queryClient = useMemo(() => {
      return new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            useErrorBoundary: false,
            staleTime: 400_000,
            refetchOnWindowFocus: false,
          },
        },
      });
    }, []);

    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };

  ComponentWithQueryClient.displayName = `withQueryClientProvider(${displayName})`;

  return ComponentWithQueryClient;
}

function App(): ReactElement {
  const collectivityName = useSelector(selectCollName);
  useFetchReservation();

  /*useEffect(() => {
    if (locale && i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);*/

  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{collectivityName}</title>
      </Helmet>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
        <Snackbar />
        <Splash />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default withQueryClientProvider(withConfig(App));
