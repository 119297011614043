/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { AppStateType } from 'pages/home/types/appState';

type Props = {
  children: ReactNode;
};

const defaultState = {
  token: '',
  reservation: undefined,
  wording: {
    bookingPaymentInfoText: '',
    cautionPaymentInfoText: '',
  },
  setAppState: () => ({}),
};

const StateContext = createContext<AppStateType>(defaultState);

const StateProvider = ({ children }: Props): ReactElement => {
  const [appState, setAppState] = useState<AppStateType>(defaultState);

  useEffect(() => {
    const URLparams = new URLSearchParams(location.search);
    const token = URLparams.get('token');

    // if (!token) {
    //   setAppState((prevState) => ({
    //     ...prevState,
    //     errorReason: 'invalidToken',
    //   }));
    // }

    if (token) {
      setAppState((prevState) => ({
        ...prevState,
        token,
      }));
    }
  }, [location]);

  // if (appState.errorReason) {
  //   return <PageError errorReason={appState.errorReason} />;
  // }

  return <StateContext.Provider value={{ ...appState, setAppState }}>{children}</StateContext.Provider>;
};

export const useAppState = (): AppStateType => useContext(StateContext);

export default StateProvider;
