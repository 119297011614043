import { useAppState } from '../internal/providers/StateProvider';
import { BookingRepository } from '../repository/Booking.repository';
import { container } from '../ioc';
import { SERVICE_KEYS } from '../ioc/keys';
import { useEffect } from 'react';
import useRequest from './useRequest';

/*type Props = {
  navigate: NavigateFunction;
};*/

function useFetchReservation() {
  // const { request: fetchResa, response } = useApi<ReservationDetailsType>(Methods.GET, 'booking/info');
  const { token, setAppState } = useAppState();
  const bookingRepository = container.get<BookingRepository>(SERVICE_KEYS.BOOKING_REPOSITORY);
  const [getBookingInfo] = useRequest(bookingRepository.getInfo);

  useEffect(() => {
    async function fetch() {
      const currentBookingInfo = await getBookingInfo(token);

      if (currentBookingInfo !== null) {
        setAppState((prevState) => ({
          ...prevState,
          reservation: currentBookingInfo,
        }));
      } else {
        // redirect ?
      }
    }

    if (token !== '') fetch();
  }, [getBookingInfo, token]);
}

export default useFetchReservation;
