/* eslint-disable max-len */

export default {
  translations: {
    global: {
      home: 'Home',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      error: 'Error message',
      send: 'Send',
      my_account: 'My account',
      book: 'OK',
      workshop: 'Workshop',
      my_reservations: 'My rentals',
      my_infos: 'My account',
      my_invoices: 'My invoices',
      sign_out: 'Sign out',
      bikes: 'Bikes',
      storage: 'Bike parking',
      rent_bike: 'Rent a bike',
      rent_storage_free: 'Rent a parking slot',
      repair_and_overhaul: 'Issue with your bike ?',
      rent_storage: 'Rent a parking slot',
      insurance: 'Insurance',
      park: 'Parking',
      page_not_found: {
        title: 'Page not found',
        wording: 'Oops ! An error has slipped under your wheel.',
      },
      go_back: 'Previous',
      renewal: 'Renewal',
    },
    routes: {
      SIGN_IN: 'Sign in',
      SIGN_UP: 'Sign out',
      CONTACT: 'Contact us',
      HOME: 'Home',
      MAIL_VALIDATION: 'Confirm your email',
      FORGOTTEN_PWD: 'Lost your password ?',
      CHANGE_PWD: 'Password management',
      MY_ACCOUNT: 'My account',
      MY_RESERVATIONS: 'My rentals',
      MY_INVOICES: 'My invoices',
      RENT_BIKE: 'Rent a bike',
      RENT_STORAGE: 'Rent a bike parking slot',
      RENT_PARK: 'Rent a bike parking slot',
      RENEWAL: 'Renewal',
    },
    splash: {
      loading: "Don't move, cycling in progress...",
      loading_title: "Don't move !",
      loading_description: 'Cycling in progress !',
    },
    error: {
      base_title: 'Error',
      unimplementedCautionPaymentMean: 'This payment mean for deposit are not implemented yet',
      default: 'A problem occurred',
      wrongBookingToken: 'Your booking has expired or is invalid',
      mandatoryField: 'This field is required',
      tokenIsEmpty: 'Booking token is empty',
      missingParameters: 'You have not filled all mandatory fields.',
      codeOrValidated: 'Please verify the information provided .Beware : you can only validate a code once.',
      alreadyAccount: 'You are already registered with this email, please sign in !',
      wrongCredentials: 'You have entered a wrong username or wrong password',
      unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
      invalidOrExpiredToken: 'Your session is expired. Please login in.',
      missingToken: 'Your session is expired. Please login in.',
      emailNotValidated: 'You have not yet authorized your e-mail. Please check your inbox and login in.',
      expiredBooking: 'Your reseration has expired. Please do the reservation process again',
      invalidBooking: 'Your reservation is invalid',
      noAvailability: 'No availability for the requested dates. Please change your reservation dates or try again',
      maxResaCapacity: 'You have reached the maximum reservation capacity for your account.',
      unknownUser: "It seems your e-mail address doesn't exist.",
      pleaseWait: 'You can only ask for a new password every 10 minutes.',
      passwordTooShort: 'Your password is too short.',
      passwordsDontMatch: "The password and it's confirmation don't match.",
      wrongResetCode: 'The link you are using seems broken.',
      resetCodeExpired: 'The link you are using seems broken, please try the password reset process again.',
      resetCodeAlreadyUsed:
        'You already have used this reset password link, please try the password reset process again.',
      wrongPayment: 'Wrong payment',
      wrongFileFormat: "Your file isn't png, pdf or jpeg file",
      deliveryPointClosed: 'Please respect the opening time of the delivery point !',
      mustBeAnAdult: 'You must be over 18',
      invalidDate: 'Invalid date format',
    },
    home: {
      welcome: 'Welcome',
      on_tool: 'on Veloclik',
      locality: '',
    },
    sign_in: {
      please_sign_in: 'Please sign in',
      forgotten_pwd: 'PLost your password ?',
      sign_up: 'Sign up',
      no_account: 'No account yet ?',
      forgotten_password: 'You have just received an email with instructions to reset your password !',
      pwd_reset_success: 'Your password has been reset with success!',
      pwd_change_success: 'Your password has been updated with success !',
      log_in: 'Sign in',
    },
    sign_up: {
      type_a_phone: 'At least one phone number must be filled in.',
      newsletter: 'like to receive more information about the service (new features, news, ...)',
      already_an_account: 'Already have an account ?',
      please_sign_up: 'Please sign up',
      nature: 'Customer type',
      individual: 'Individual',
      association: 'Association',
      company: 'Company',
      administration: 'Public administration',
      civility: 'Title',
      mr: 'Mr',
      mrs: 'Mrs',
      firm: 'Firm',
      firm_or_asso: 'Company / Charity (facultatif)',
      last_name: 'Last name',
      first_name: 'First name',
      email: 'Email',
      password: 'Password',
      birthdate: 'Birthdate',
      origin_country: 'Country of birth',
      address: 'Address',
      full_address: 'Address complement',
      zip: 'ZIP code',
      city: 'City',
      country: 'Country',
      phone: 'Phone number',
      mobile_phone: 'Cellphone number',
      email_error: 'Email format is an incorrect format',
      password_error: '8 or more characters and only letters and capitals',
      password_requirements: 'Your password must contain 8 or more characters and only letters and capitals',
      sign_up_success:
        'Thanks for your registration. We will send you an email to verify your email address, then you may log in !',
      copy_password: 'My password (confirmation)',
      my_pwd: 'My password',
      change_pwd_description:
        'For security reasons, at the first time you sign in, you have to change the password you\ve receive in email',
    },
    contact: {
      title: 'Contact us',
      contact_us: 'Contact us',
      let_your_contact: 'Please contact us and let us your contact info so that we could answer you !',
      success: 'Your request has been sent !',
      input_label: 'Sender :',
    },
    forgotten_pwd: {
      title: 'Lost your password ?',
      description: "Don't worry, it can happen to anyone !",
      button: 'Request a new password',
    },
    mail_validation: {
      title: 'Email verification',
      please_verify: 'Please enter your e-mail address and the security code you have just received by email',
      code: 'Security code',
      verify: 'Confirm my account',
      has_no_account: 'No account yet ?',
      forgotten_pwd: 'Lost your password ?',
      email_validated: 'Your email has been validated with success ! You can now sign in !',
    },
    my_account: {
      update_infos: 'Edit Personal Details',
      infos_updated: 'Your personal details have been updated !',
      you_can_modify: 'You can edit your personal details here',
    },
    my_reservations: {
      no_contracts: 'No contract',
      consult_here: 'Consultez here your current and past reservations .',
      current_reservations: 'Current reservations ({{currentResa}})',
      past_reservations: 'Past reservations ({{pastResa}})',
    },
    my_invoices: {
      price_type: 'TTC',
      no_invoice: 'Aucune facture en cours',
      header_description: 'Consultez ici toutes vos factures',
      tab_title: 'Facture',
    },
    workshop: {
      title: 'Workshop',
      meet_at_workshop: 'Meet us at the workshop.',
      check_up:
        'Your bike needs a check up ? We offer a maintenance service including advices and answers to all the question you have about the maintenance of bike',
      opening_times: 'Opening Times',
      opening_hours: 'monday to fridy : 7.30AM - 12PM et 2PM - 7.30PM',
      saturday: 'saturday : 10AM - 1PM',
      street: '1 ter Place Charles de Gaulle',
      city: '78180 Montigny-le-Bretonneux',
      tel: 'Tel: {{ item }}',
      mail: 'Email: {{ item }}',
    },
    resabike: {
      price_ttc: 'Prices include taxes',
      book_a_bike: 'Select your bike :',
      free_price: 'Free',
      free_total: 'Nothing to pay',
      rental_prices: 'Rental prices for a bike ',
      standard: 'standard',
      choose_duration: 'Select your price :',
      back: 'Previous page',
      book: 'BOOK',
      description: 'Service offers a variety of different bikes',
      select_title: 'Select your location :',
      select_holder: 'Select your location',
      bike_number: '{{bikeNumber}} bikes',
      park_number: '{{bikeNumber}} parking',
      want_pay_caution_with_cheque:
        'If you want to pay your deposit with bank check, please bring it when picking the bike up !',
      caution_section_title: 'Deposit registration',
      caution_is_paid: 'Deposit has been registered with success !',
      caution_is_paid_title: 'Deposit registered',
      caution_section_cta: 'Register deposit',
      bike_disponibility: ' {{bikeType}}  availables',
      secured_park: 'parking slots',
      ttc_day: 'inc tax / day',
      other_price: '+ other prices',
      form_start_date: 'Starting date',
      form_end_date: 'Ending date',
      you_choose_a: 'You have selected a',
      you_choose_a_park: 'You have selected a',
      tenant_info: '<b>Rental assigned to : </b>{{tenant}}',
      iban: 'IBAN',
      bic: 'BIC',
      invalid_iban: 'IBAN format is not valid',
      invalid_bic: 'BIC format is not valid',
      duration: 'Duration',
      min: 'minimum',
      max: 'maximum',
      electric: 'You have an e-bike ?',
      of_rental: 'of rental for the chosen price:',
      id_card: 'Proof of identity (ID card, driver licence)',
      RIB: 'Bank Account Identifier (for the penalty)',
      if_you_dont_have: "If you don't have these proofs, please come in agency.",
      please_give_IBAN: 'Please give your bank account information to ease your reservations :',
      proccess_payment: 'Pay with credit card',
      your_data_wont_saved: " (beware, your data won't be saved)",
      you_choose: 'You choose :',
      rental_is_to: 'This rental will be assigned to: ',
      total_cost: 'Total price : ',
      please_wait: 'Please wait...',
      wait_validation: 'YYour payment is being processed...',
      congratulation: 'Congratulations !',
      pay: 'Pay',
      payWithAmount: 'Pay now {{price}}',
      recap_choice: 'you have selected : ',
      recap_security_deposit: 'Cashed deposit : ',
      recap_location: 'This rental will be assigned to : ',
      recap_total: 'Total price : ',
      recap_title: 'Payment summary',
      recap_caution_title: 'Deposit',
      we_have_several_bikes: 'We offer a variety of different bikes.',
      we_have_several_parks: 'We offer a variety of parking slots.',
      payment_invalid: 'Payment failed',
      form_token_expired: 'The transaction has expired or the payment form is no longer available',
      payment_failed: 'The payment failed, please try again.',
      payment_failed_title: 'Failed payment',
      security_deposit_choose: ' + Cashed deposit',
    },
    resa_storage: {
      title_header: 'Rent a parking slot',
      transport_card: {
        first_descr: 'A valid Navigo card is',
        second_descr: 'MANDATORY',
        third_descr: 'to get access to our secure parking spaces! It is composed of 10 numbers',
        fourth_descr: '(with no letters)',
        fifth_descr: 'and displayed in your card',
      },
      transport_card_placeholder: 'Navigo Card ID',
      transport_card_upload: 'Proof purchase of your Navigo card',
      btn_pay: 'Go to payment',
      invalid_transport_card: 'You card number is not valid',
      modal_transport_card: {
        link: 'Where is the number ?',
        title: 'Where to find the number ?',
        close: 'Close',
        first_descr: 'Indicate the number',
        second_descr: 'WITH NO SPACE AND NO LETTERS',
        third_descr: 'of your Navigo card (see the above)',
        fourth_descr: 'Example : for',
        fifth_descr: '0123456789',
      },
    },
    paymentSchedule: {
      title: 'Payment schedule',
      advice: 'Your payment will be monthly scheduled 1 month after the start of your subscription like so',
    },
    payment: {
      title: 'Rental payment',
      advice: 'You can pay by debit/credit card only.',
      total_title: 'To be paid',
      total_is_paid_title: 'Paid',
      security_deposit_wording: 'and cashed deposit collected : {{ amountSecurityDeposit }} {{ currency }} inc tax',
      renewal_title: 'Renewal payment',
      success: 'Your payment has been received with success!',
      success_deposit: 'Your deposit method has been registered with success!',
      caution_title: 'Deposit to be paid {{ caution }}: {{ price }} {{ currency }}',
      booking_title: 'Payment of {{ article }} : {{ price }} {{ currency }} inc tax',
      caution_wording: 'For the rental of {{ article }} a deposit of {{ price }} {{ currency }} inc tax is required',
      caution_wording_2: 'For your rental, a deposit {{ caution }} of {{ price }} {{ currency }} inc tax is required',
      choose: 'Please choose a payment method for the payment of the reservation',
      agency_payment: 'We wait for you for the paymet of your {{ type }} ',
      pay_booking: 'Proceed to payment',
      finish_booking: 'View rental confirmation',
      processing: 'Your payment is being processed',
      type: {
        booking: 'rental',
        deposit: 'deposit',
      },
      caution_mean: {
        cb: 'Credit/Debit Card deposit',
        agency: 'Register at checkin',
        sepa: 'SEPA deposit',
      },
      mean: {
        cb: 'Credit/Debit Card payment',
        agency: 'Payment at checkin',
        sepamult: 'Monthly SEPA payment',
        sepa: 'SEPA payment',
        cbmult: 'Payment in {{reccurence}} instalments without fees',
      },
    },
    form: {
      notice: 'Fields with * are mandatory.',
      errors: {
        caution: {
          required: 'You must select an option',
        },
      },
    },
    languages: {
      fr: 'FR 🇫🇷',
      en: 'EN 🇬🇧',
    },
    stripePage: {
      cardNumber: 'Card number',
      expirationDate: 'Expiry',
      cvc: 'CVC',
    },
  },
};
