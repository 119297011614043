import React, { ForwardedRef, ReactNode } from 'react';
import SnackbarSeverity from '../../internal/constants/snackbar';
import classNames from 'classnames/bind';
import styles from './toast.module.css';
import { upperFirst } from 'lodash';
import { ReactComponent as ErrorIcon } from 'assets/svg/warning.svg';
import { ReactComponent as SuccessIcon } from 'assets/svg/check.svg';

const cx = classNames.bind(styles);

const ICON_WIDTH = 32;

const mapIconBySeverity: Partial<Record<SnackbarSeverity, ReactNode>> = {
  error: <ErrorIcon width={ICON_WIDTH} />,
  success: <SuccessIcon width={ICON_WIDTH} />,
};

type Props = {
  severity?: SnackbarSeverity;
  message: string;
  title: string;
};

function Toast({ severity = SnackbarSeverity.INFO, message, title }: Props, ref: ForwardedRef<HTMLDivElement>) {
  const wrapperClassName = cx('Wrapper', styles[upperFirst(severity)]);

  return (
    <div ref={ref} className={wrapperClassName}>
      {mapIconBySeverity[severity]}
      <div className={styles.Content}>
        <h6 className={styles.Title}>{title}</h6>
        <p>{message}</p>
      </div>
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, Props>(Toast);
