import React, { ComponentType, lazy, LazyExoticComponent } from 'react';

export type RouteType<T = Record<string, unknown>> = {
  path: string;
  layout?: ComponentType;
  element?: LazyExoticComponent<React.ComponentType>;
  privateRoute?: boolean;
  children?: Record<string, RouteType>;
  sampleParams?: T;
};

export function from<A = Record<string, unknown>, B = Record<string, unknown>>(base: RouteType<A>, add: RouteType<B>) {
  const { path, sampleParams, ...other } = add;

  const result: RouteType<A & B> = {
    path: `${base.path}${path}`,
    sampleParams: base.sampleParams || sampleParams ? Object.assign({}, base.sampleParams, sampleParams) : undefined,
    ...other,
  };

  return result;
}

const home: RouteType = {
  path: '/',
  element: lazy(() => import('pages/home/components/Home')),
};

const stripeSca: RouteType = {
  path: '/stripe/cb',
  element: lazy(() => import('payment/components/StripeScaWrapper')),
};

const stripeCCRecurrent: RouteType = {
  path: '/stripe/recurrent-cc',
  element: lazy(() => import('pages/stripe/ReccurentCC.page')),
};

const sogecommerceSca: RouteType = {
  path: '/sogecommerce/cb',
  element: lazy(() => import('payment/components/SogecScaWrapper')),
};

const sogecommerceSepa: RouteType = {
  path: '/',
  element: lazy(() => import('payment/components/SogecScaWrapper')),
};

const routes = {
  home,
  stripeSca,
  stripeCCRecurrent,
  sogecommerceSca,
  sogecommerceSepa,
};

export type RouteNames = keyof typeof routes;

export default routes;
