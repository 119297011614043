import instance from './instance';

const ENDPOINTS = {
  getTranslations: 'root/translations',
  getConfig: 'root/config',
};

export function getTranslations(bookingToken: string, locale?: string | null) {
  //eslint-disable-next-line
  return instance.get<{ locale: string; translations: any }>(ENDPOINTS.getTranslations, {
    params: { lang: locale, bookingToken },
  });
}

export function getConfig(bookingToken: string) {
  return instance.get(ENDPOINTS.getConfig, { params: { bookingToken } });
}
