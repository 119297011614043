import React from 'react';
import BaseNavbar from '../components/Navbar/BaseNavbar';
import { VdiLogoDark } from '../const/icons';
import { Outlet } from 'react-router-dom';

function DefaultLayout() {
  return (
    <>
      <BaseNavbar leftSlot={<VdiLogoDark width={94} />} />
      <Outlet />
    </>
  );
}

export default DefaultLayout;
