import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getConfig, getTranslations } from '../api/config';
import { useLoading } from '../context/LoadingContext';
import { changeLocale, savePageWordings, saveRemoteConfig } from '../internal/actions/appActions';
import { useAppState } from '../internal/providers/StateProvider';
import { selectLocale } from '../internal/selectors/localeSelectors';
import { selectDefaultLanguage } from '../internal/selectors/remoteConfigSelectors';
import { useQuery } from '@tanstack/react-query';

function withConfig<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithConfig = (props: T) => {
    const { i18n } = useTranslation();
    const { token, setAppState } = useAppState();
    const dispatch = useDispatch();
    const { setDataIsLoaded, setLoading } = useLoading();
    const locale = useSelector(selectLocale);
    const defaultLocale = useSelector(selectDefaultLanguage);

    useQuery(
      ['root/config', token],
      ({ queryKey }) => {
        const [, token] = queryKey;
        return getConfig(token);
      },
      {
        onSuccess: (response) => {
          dispatch(saveRemoteConfig(response.data));

          if (locale && !response.data.client.languages.active.includes(locale as 'fr' | 'en')) {
            dispatch(changeLocale(response.data.client.languages.default || 'fr'));
          }
        },
        onError: () => {
          dispatch(changeLocale(navigator.language.split('-')[0]));
        },
      }
    );

    const { isLoading } = useQuery(
      ['root/translations', token, locale],
      ({ queryKey }) => {
        const [, _token, _locale] = queryKey;
        if (!_token) throw new Error('token is missing');
        return getTranslations(_token, _locale);
      },
      {
        onSuccess: (response) => {
          if (response?.data.translations)
            i18n.addResourceBundle(response.data.locale, 'translations', response.data.translations, true, true);

          setAppState((prevState) => ({
            ...prevState,
            wording: {
              bookingPaymentInfoText: response.data.translations.dynamicStrings?.bookingPaymentInfoText || '',
              cautionPaymentInfoText: response.data.translations.dynamicStrings?.cautionPaymentInfoText || '',
            },
          }));
          dispatch(savePageWordings(response.data.translations.dynamicWording));

          setDataIsLoaded(true);
        },
        onError: () => {
          setDataIsLoaded(true);
        },
      }
    );

    useEffect(() => {
      if (isLoading) {
        setLoading(true);
        setDataIsLoaded(false);
      }
    }, [isLoading]);

    useEffect(() => {
      if (locale && i18n.language !== locale) {
        i18n.changeLanguage(locale);
      } else if (!locale && defaultLocale) {
        dispatch(changeLocale(defaultLocale));
        if (i18n.language !== defaultLocale) i18n.changeLanguage(defaultLocale);
      }
    }, [i18n, locale, defaultLocale]);

    return <WrappedComponent {...props} />;
  };

  ComponentWithConfig.displayName = `withConfig(${displayName})`;

  return ComponentWithConfig;
}

export default withConfig;
