import useRequireAuthorize from 'hook/auth/useRequireAuthorize';
import React from 'react';

const RequireRouteEnable = <P extends object>(WrappedComponent: React.ComponentType<P>, path: string) => {
  return (props: P) => {
    useRequireAuthorize(path);

    return <WrappedComponent {...props} />;
  };
};

export default RequireRouteEnable;
