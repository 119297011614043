import i18n from 'i18next';
import en from 'internal/constants/en';

import fr from 'internal/constants/fr';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en,
  fr,
} as const;

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

export type ValidI18nKey = Leaves<typeof fr, 5>;

export type NestedValueOf<Obj, Key extends string> = Obj extends object
  ? Key extends `${infer Parent}.${infer Leaf}`
    ? Parent extends keyof Obj
      ? NestedValueOf<Obj[Parent], Leaf>
      : never
    : Key extends keyof Obj
    ? Obj[Key]
    : never
  : never;

i18n.use(initReactI18next).init({
  lng: 'fr',
  // fallbackLng: 'fr',
  debug: true,
  ns: ['translations'],
  // defaultNS: 'translation',
  // fallbackNS: 'fallback',
  resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'loaded languageChanged',
    bindI18nStore: 'loaded added',
  },
});

interface TranslateFunction {
  (message: ValidI18nKey, params?: { [key: string]: string | number | undefined }): NestedValueOf<
    typeof fr,
    ValidI18nKey
  >;
  (message: string, params?: { [key: string]: string | number | undefined }): string;
}

export const t: TranslateFunction = i18n.t.bind(i18n);

export default i18n;
