import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MainDefault from '../../components/MainDefault/MainDefault';
import { TimeoutPlaceholder } from '../../const/icons';

export type ErrorReasonType = 'invalidToken' | 'invalidResa';

const PageError = (): ReactElement | null => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [error, setError] = useState<ErrorReasonType>('invalidToken');
  const [render, setRender] = useState(false);

  /*const setNavigatorLocale = useCallback(async () => {
    const [locale] = navigator.language.split('-');
    await i18n.changeLanguage(locale);
    setRender(true);
  }, []);*/

  useEffect(() => {
    // setLoading(false);
    setRender(true);
    // setNavigatorLocale();
  }, []);

  useEffect(() => {
    if (state) {
      const { error } = state as { error: ErrorReasonType };
      if (error) setError(error);
    }
  }, [state]);

  /*const handleGoBack = useCallback(() => {
    window.history.back();
  }, []);*/

  const renderReasonTitle = useMemo(() => {
    if (!render) {
      return null;
    }
    switch (error) {
      case 'invalidToken':
        return t('error.invalidOrExpiredToken');

      case 'invalidResa':
        return t('error.invalidBooking');

      default:
        return t('error.default');
    }
  }, [render, error, t]);

  if (!render) {
    return null;
  }

  return (
    <MainDefault>
      <div className={'container relative pt-9'}>
        <div className={'flex flex-col items-center justify-center text-center'}>
          <TimeoutPlaceholder />
          <h6 className={'font-bold mt-8'}>{renderReasonTitle}</h6>
          <p className={'whitespace-pre mt-1'}>{t('global.page_not_found.wording')}</p>
        </div>
      </div>
    </MainDefault>
  );
};

export default PageError;
