import { changeLocale } from 'internal/actions/appActions';
import useClickOutside from 'internal/hooks/useClickOutside';
import { selectRemoteLanguages } from 'internal/selectors/remoteConfigSelectors';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const LangSelect = () => {
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const availableLanguages = useSelector(selectRemoteLanguages);
  const dispatch = useDispatch();

  // const { request: postLang } = useApi(Methods.POST, 'user/lang');

  const onChangeLocale = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale).then(() => {
        /*postLang({
          lang: locale,
        });*/
        dispatch(changeLocale(locale));
        // setLoading(true);
        setIsOpen(false);
        // window.location.reload();
      });
    },
    [i18n, changeLocale]
  );

  useClickOutside(dropdownRef.current, () => setIsOpen(false));

  return availableLanguages.length > 1 ? (
    <div ref={dropdownRef} className={'relative z-60 md:ml-6 w-[40px] h-[30px]'}>
      <div
        className={
          'text-navbar-color border-navbar-color grid bg-navbar-background grid-cols-1 w-full absolute top-0 right-0 uppercase border-2 cursor-pointer rounded-lg'
        }
      >
        <span onClick={() => setIsOpen(!isOpen)} className={'block px-2 py-1 text-sm font-bold'}>
          {i18n.language}
        </span>
        {isOpen && (
          <ul className={'grid grid-cols-1'}>
            {availableLanguages.map((lang) => (
              <li
                key={lang}
                onClick={() => onChangeLocale(lang)}
                className={'text-sm hover:text-navbar-hover px-2 py-1 font-bold'}
              >
                {/* {t(`languages.${lang}`)} */}
                {lang}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  ) : null;
};

export default LangSelect;
