import { combineReducers } from '@reduxjs/toolkit';

import appState from 'internal/reducers/appReducer';

const rootReducer = combineReducers({
  appState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
