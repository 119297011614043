import 'reflect-metadata';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'internal/components/App';
import store, { persistor } from 'internal/store';
import * as serviceWorker from 'serviceWorker';
import 'index.css';
import './internal/i18n';
import StateProvider from 'internal/providers/StateProvider';
import LoadingProvider from './context/LoadingContext';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StateProvider>
        <LoadingProvider>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </LoadingProvider>
      </StateProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
