import React, { ReactElement, useEffect } from 'react';
import { useLoading } from '../../context/LoadingContext';
import { useTranslation } from 'react-i18next';
import { PageNotFoundPlaceholder } from '../../const/icons';
import MainDefault from '../../components/MainDefault/MainDefault';

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();

  useEffect(() => {
    // TODO: better solution for hide splash screen ?
    setLoading(false);
  }, []);

  return (
    <MainDefault>
      <div className={'container pt-9 mt-10 grid text-center justify-center'}>
        <PageNotFoundPlaceholder className={'text-primary-200'} />
        <h6 className={'font-bold mt-8'}>{t('global.page_not_found.title')}</h6>
        <p className={'whitespace-pre mt-1'}>{t('global.page_not_found.wording')}</p>
      </div>
    </MainDefault>
  );
};

export default PageNotFound;
