import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import NewNavbar from '../components/Navbar/NewNavbar';
import { useLoading } from '../context/LoadingContext';

function AppLayout() {
  const { loading } = useLoading();

  return loading ? null : (
    <>
      <NewNavbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default AppLayout;
