import { RootState } from 'internal/rootReducer';
import {
  ContactType,
  RemoteFieldType,
  RemotePaymentConfigType,
  RemoteRouteType,
  RemoteVoucherSectionsType,
  ThemeColors,
  ThemeConfig,
  WorkshopType,
} from 'internal/types/remoteConfig';

export const selectCollId = (state: RootState): string | undefined => state.appState.config?.client?.id?.toString();

export const selectCollName = (state: RootState): string | undefined => state.appState.config?.client?.theme?.nom;

export const selectRoutes = (state: RootState): RemoteRouteType | undefined => state.appState.config?.routes;

export const selectRemoteTheme = (state: RootState): (ThemeConfig & ThemeColors) | undefined =>
  state.appState.config?.client?.theme;

export const selectRemoteSignupForm = (state: RootState): RemoteFieldType[][] | undefined =>
  state.appState.config?.form?.signup?.fields;

export const selectRemoteUserEditionForm = (state: RootState): RemoteFieldType[][] | undefined =>
  state.appState.config?.form?.userEdition?.fields;

export const selectRemoteBookingForm = (state: RootState): RemoteFieldType[][] | undefined =>
  state.appState.config?.form?.booking?.fields;

export const selectRemoteLanguages = (state: RootState): ('fr' | 'en')[] =>
  state.appState.config?.client?.languages?.active || ['fr'];

export const selectDefaultLanguage = (state: RootState): ('fr' | 'en') | undefined =>
  state.appState.config?.client?.languages?.default;

export const selectWorkshop = (state: RootState): WorkshopType | undefined => state.appState.config?.client?.workshop;

export const selectContact = (state: RootState): ContactType | undefined => state.appState.config?.client?.address;

export const selectVouchers = (state: RootState): RemoteVoucherSectionsType | undefined => state.appState.config?.pj;

export const selectRemotePaymentConf = (state: RootState): RemotePaymentConfigType => ({
  booking: state.appState.config?.booking,
  cautions: state.appState.config?.cautions,
});

export const selectRemoteClientInsurance = (state: RootState): string =>
  state.appState.config?.client?.url_assurance || '';

export const selectRemoteFrontUrl = (state: RootState): string => state.appState.config?.client?.url_front || '';
