import Link from 'components/Link/Link';
import React from 'react';
import { RouteType } from 'router/routes';

type Props = {
  list: {
    label: string;
    to: RouteType;
    params?: Record<string, unknown>;
  }[];
};

const MenuDropdown = ({ list }: Props) => {
  return (
    <div className={'rounded bg-navbar-background shadow min-w-[124px]'}>
      <ul className={'flex flex-col w-full'}>
        {list.map(({ label, ...link }, index) => {
          return (
            <React.Fragment key={label + index}>
              <li>
                <Link
                  notThemed
                  className={'py-2 block px-2 font-bold text-xs text-navbar-color hover:text-navbar-hover'}
                  {...link}
                >
                  {label}
                </Link>
              </li>
              {index !== list.length - 1 && <hr className={'border-t-primary-300'} />}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuDropdown;
