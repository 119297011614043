import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectSnackbarState } from '../../internal/selectors/snackbarSelectors';
import { Snackbar as SnackbarMui } from '@mui/material';
import { useLoading } from '../../context/LoadingContext';
import Toast from '../Toast/Toast';
import SnackbarSeverity from '../../internal/constants/snackbar';

function Snackbar() {
  const [toastContent, setToastContent] = useState<{ title: string; message: string; severity?: SnackbarSeverity }>({
    title: '',
    message: '',
    severity: SnackbarSeverity.INFO,
  });
  const { snackbar } = useSelector(SelectSnackbarState);
  const { loading } = useLoading();

  useEffect(() => {
    //delay the content
    if (snackbar.content === '') {
      const timer = setTimeout(() => {
        setToastContent({ message: '', title: '' });
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      setToastContent({ message: snackbar.content, title: snackbar.title, severity: snackbar.severity });
    }
  }, [snackbar.content]);

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbar.content !== '' && !loading}
      ClickAwayListenerProps={{
        onClickAway: () => {
          return;
        },
      }}
      disableWindowBlurListener
    >
      <Toast title={toastContent.title} severity={toastContent.severity} message={toastContent.message} />
    </SnackbarMui>
  );
}

export default Snackbar;
