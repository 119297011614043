import classNames from 'classnames';
import useIsAuthenticated from 'hook/auth/useIsAuthenticated';
import React, { HtmlHTMLAttributes, useMemo } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import MenuDropdown from './MenuDropdown';
import Link from '../Link/Link';
import FRONT_ROUTES from 'router/frontRoutes';
import { useTranslation } from 'react-i18next';
import { RouteType } from '../../router/routes';
import { useSelector } from 'react-redux';
import { selectRemoteFrontUrl } from '../../internal/selectors/remoteConfigSelectors';
import { generateFrontLink } from '../../const/footerLinks';

type MenuItem = {
  label: string;
} & (
  | {
      to: RouteType;
    }
  | {
      children: {
        label: string;
        to: RouteType;
      }[];
    }
);

const DesktopNavbar: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { t } = useTranslation();
  const itemClassName = 'uppercase font-bold text-xs text-navbar-color hover:text-navbar-hover';
  const className = classNames(props.className);
  const isAuthenticated = useIsAuthenticated();
  const frontBaseUrl = useSelector(selectRemoteFrontUrl);
  const CONSTRUCT_FRONT_ROUTES = generateFrontLink(frontBaseUrl, FRONT_ROUTES);

  const MENU = useMemo<MenuItem[][]>(
    () => [
      [{ label: t('contact.title'), to: CONSTRUCT_FRONT_ROUTES.contact }],
      [
        {
          label: t('global.book'),
          children: [
            {
              label: t('global.rent_bike'),
              to: CONSTRUCT_FRONT_ROUTES.rentBike,
              // TODO: replace with a enum or const ('velo')
              params: { type: 'velo' },
            },
            {
              label: t('global.rent_storage'),
              to: CONSTRUCT_FRONT_ROUTES.rentBike,
              params: { type: 'consigne' },
            },
          ],
        },
        {
          label: t('global.repair_and_overhaul' as never),
          to: CONSTRUCT_FRONT_ROUTES.repairAndOverhaul,
        },
      ],
    ],
    [t]
  );

  return (
    <div className={className}>
      {MENU[isAuthenticated ? 1 : 0].map((item) => {
        return 'children' in item ? (
          <Dropdown key={item.label} className={'flex'} buttonClassName={itemClassName} title={item.label}>
            {() => <MenuDropdown list={item.children} />}
          </Dropdown>
        ) : (
          <Link key={item.label} className={itemClassName} to={item.to}>
            {item.label}
          </Link>
        );
      })}
    </div>
  );
};

export default DesktopNavbar;
