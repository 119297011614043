import {
  CHANGE_LOCALE,
  HIDE_SNACKBAR,
  SAVE_DEFAULT_LOCALE,
  SAVE_PAGE_WORDINGS,
  SAVE_REMOTE_CONFIG,
  SET_SESSION_TOKEN,
  SET_SNACKBAR_CONTENT,
  SHOW_SNACKBAR,
} from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import { WordingsType } from 'internal/mocks/wordings';
import { AppActionType } from 'internal/types/actions';
import { RemoteConfigType } from 'internal/types/remoteConfig';

type AppState = {
  token: string | null;
  snackbar: {
    title: string;
    content: string;
    severity: SnackbarSeverity;
  };
  snackbarOpened: boolean;
  locale: string;
  defaultLocale: string | null;
  config: RemoteConfigType | null;
  wordings: WordingsType | null;
  dynamicWordings?: Record<string, string>;
};

const initialState = {
  //@XXX : Le token n'est pas dynamique pour l'instant :/
  token: '173b397ae5e68523bcd5eeb7e435a017',
  snackbar: {
    title: '',
    content: '',
    severity: SnackbarSeverity.INFO,
  },
  snackbarOpened: false,
  // @xxx Transform 'fr-FR' to 'fr'
  locale: navigator.language.split('-')[0],
  defaultLocale: null,
  config: null,
  wordings: null,
  dynamicWordings: undefined,
};

const appReducer = (state: AppState = initialState, action: AppActionType): AppState => {
  switch (action.type) {
    case SET_SESSION_TOKEN:
      const { token } = action.payload;

      return {
        ...state,
        token,
      };

    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarOpened: true,
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbarOpened: false,
      };

    case SET_SNACKBAR_CONTENT:
      const { title, content, severity } = action.payload;

      return {
        ...state,
        snackbar: {
          title,
          content,
          severity: severity || state.snackbar.severity,
        },
      };

    case CHANGE_LOCALE:
      const { locale } = action.payload;

      return {
        ...state,
        locale,
      };

    case SAVE_DEFAULT_LOCALE:
      const { locale: defaultLocale } = action.payload;

      return {
        ...state,
        defaultLocale,
      };

    case SAVE_REMOTE_CONFIG:
      const { config } = action.payload;

      return {
        ...state,
        config,
      };

    case SAVE_PAGE_WORDINGS:
      const { wordings } = action.payload;

      return {
        ...state,
        wordings,
      };

    default:
      return state;
  }
};

export default appReducer;
