import axios, { AxiosInstance } from 'axios';
import { injectable } from 'inversify';
import { API_KEY, BASE_URL, TIMEOUT } from '../internal/constants/api';

@injectable()
export class Api {
  public instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: BASE_URL,
      timeout: TIMEOUT,
      headers: {
        ['X-Veloclik-Apikey']: API_KEY,
      },
    });
  }
}
