import { createStore, compose } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from 'internal/rootReducer';

const persitingAppReducers = createWhitelistFilter('appState', ['token', 'locale', 'config', 'wordings']);
const userStateReducer = createWhitelistFilter('userState', ['user']);

// eslint-disable-next-line
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: ['appState', 'userState'],
  transforms: [persitingAppReducers, userStateReducer],
  stateReconciler: autoMergeLevel2,
};

const composeEnhancers =
  // eslint-disable-next-line
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// eslint-disable-next-line
export const persistor = persistStore(store as any);

export default store;
