import { Container } from 'inversify';
import { BookingRepository } from '../repository/Booking.repository';
import { Api } from '../api';
import { SERVICE_KEYS } from './keys';
import { AppRepository } from '../repository/App.repository';

export const container = new Container();

container.bind<BookingRepository>(SERVICE_KEYS.BOOKING_REPOSITORY).to(BookingRepository).inSingletonScope();
container.bind<AppRepository>(SERVICE_KEYS.APP_REPOSITORY).to(AppRepository).inSingletonScope();
container.bind<Api>(SERVICE_KEYS.API_INSTANCE).to(Api).inSingletonScope();
