import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FRONT_ROUTES from 'router/frontRoutes';
import { selectRemoteFrontUrl } from '../internal/selectors/remoteConfigSelectors';
import { RouteType } from '../router/routes';

export function generateFrontLink(frontBaseUrl: string, allFrontRoutes: Record<string, RouteType>) {
  return Object.entries(allFrontRoutes).reduce((acc, [routeName, route]) => {
    return {
      ...acc,
      [routeName]: {
        path: frontBaseUrl + route.path,
      },
    };
  }, {} as typeof FRONT_ROUTES);
}

function useGetFooterLinks() {
  const { t } = useTranslation();
  const frontBaseUrl = useSelector(selectRemoteFrontUrl);
  const CONSTRUCT_FRONT_ROUTES = generateFrontLink(frontBaseUrl, FRONT_ROUTES);

  return useMemo(() => {
    return [
      {
        label: 'Le service',
        links: [
          {
            label: t('global.rent_bike'),
            to: CONSTRUCT_FRONT_ROUTES.rentBike,
            params: { type: 'velo' },
          },
          {
            label: t('global.workshop'),
            to: CONSTRUCT_FRONT_ROUTES.workshop,
          },
          {
            label: t('global.repair_and_overhaul'),
            to: CONSTRUCT_FRONT_ROUTES.repairAndOverhaul,
          },
          {
            label: t('contact.contact_us'),
            to: CONSTRUCT_FRONT_ROUTES.contact,
          },
        ],
      },
      {
        label: t('global.my_account'),
        links: [
          {
            label: t('global.my_reservations'),
            to: CONSTRUCT_FRONT_ROUTES.myReservations,
          },
          {
            label: t('global.my_invoices'),
            to: CONSTRUCT_FRONT_ROUTES.myInvoices,
          },
        ],
      },
    ];
  }, [t]);
}

export default useGetFooterLinks;
