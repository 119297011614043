import { useDispatch, useSelector } from 'react-redux';
import { SelectSnackbarState } from '../internal/selectors/snackbarSelectors';
import { useCallback, useEffect } from 'react';
import { setSnackbar } from '../internal/actions/appActions';
import SnackbarSeverity from '../internal/constants/snackbar';

const AUTO_DISMISS = 5000;

function useSnackbar() {
  const dispatch = useDispatch();
  const { snackbar } = useSelector(SelectSnackbarState);

  useEffect(() => {
    if (snackbar.content !== '') {
      const timer = setTimeout(() => dispatch(setSnackbar('', '')), AUTO_DISMISS);
      return () => clearTimeout(timer);
    }
  }, [snackbar.content]);

  const setAlert = useCallback((title: string, description: string, severity: SnackbarSeverity) => {
    dispatch(setSnackbar(title, description, severity));
  }, []);

  return {
    setAlert,
  };
}

export default useSnackbar;
