import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { SERVICE_KEYS } from '../ioc/keys';
import { Api } from '../api';
import { RemoteConfigType } from '../internal/types/remoteConfig';

type RemoteTranslationsResponse = {
  locale: string;
  translations: Record<string, Record<string, string>>;
};

const ENDPOINTS = {
  config: 'root/config',
  translations: 'root/translations',
};

interface AppRepositoryInterface {
  getConfig(token: string): Promise<AxiosResponse<RemoteConfigType>>;
  getTranslations(token: string): Promise<AxiosResponse<RemoteTranslationsResponse>>;
}

@injectable()
export class AppRepository implements AppRepositoryInterface {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getConfig = async (token: string): Promise<AxiosResponse<RemoteConfigType>> => {
    if (!this._apiInstance) throw new Error('api dependency is not init');
    return await this._apiInstance.instance.get(ENDPOINTS.config, { params: { bookingToken: token } });
  };

  getTranslations = async (token: string): Promise<AxiosResponse<RemoteTranslationsResponse>> => {
    if (!this._apiInstance) throw new Error('api dependency is not init');
    return await this._apiInstance.instance.get(ENDPOINTS.translations, { params: { bookingToken: token } });
  };
}
