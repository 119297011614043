import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SnackbarSeverity from '../internal/constants/snackbar';
import useSnackbar from './useSnackbar';

// type UseRequestReturn<T> = T | null;

type UseRequestReturn<T, P> = [(params: P) => Promise<T | null>];

function useRequest<T = unknown, P = unknown>(
  request: (params: P) => Promise<AxiosResponse<T>>
): UseRequestReturn<T, P> {
  const { setAlert } = useSnackbar();
  const { t } = useTranslation();
  const [, setData] = useState<T | null>(null);

  const callback = useCallback(
    async (params: P) => {
      try {
        const { data, status } = await request(params);
        if (status !== 200) {
          // console.log('error, display snackbar');
        }
        setData(data);

        return data;
      } catch (e: unknown) {
        if (isAxiosError(e)) {
          setAlert(t('error.base_title'), t(e.response?.data.code), SnackbarSeverity.ERROR);
        }

        return null;
      }
    },
    [request, t]
  );

  return [callback];
}

// eslint-disable-next-line
function isAxiosError(error: any): error is AxiosError {
  return 'isAxiosError' in error && error.isAxiosError;
}

export default useRequest;
