/* eslint-disable max-len */

export default {
  translations: {
    global: {
      home: 'Accueil',
      sign_in: 'Se connecter',
      sign_up: "S'inscrire",
      error: "Message d'info / d'erreur",
      send: 'Envoyer',
      my_account: 'Mon compte',
      book: 'Réserver',
      workshop: "L'atelier",
      my_reservations: 'Mes réservations',
      my_infos: 'Mes informations',
      my_invoices: 'Mes factures',
      sign_out: 'Se déconnecter',
      bikes: 'Vélos',
      storage: 'Consignes libres',
      rent_bike: 'Réserver 1 vélo',
      rent_storage_free: 'Réserver 1 consigne libre',
      repair_and_overhaul: 'Réparation et révision',
      rent_storage: 'Réserver 1 consigne',
      insurance: 'Assurance',
      park: 'Consignes',
      page_not_found: {
        title: 'Page non trouvée',
        wording: "Oups ! Une erreur s'est glissée sous votre roue.",
      },
      go_back: 'Revenir en arrière',
      renewal: 'Renouvellement',
    },
    routes: {
      SIGN_IN: 'Se connecter',
      SIGN_UP: "S'inscrire",
      CONTACT: 'Contact',
      HOME: 'Accueil',
      MAIL_VALIDATION: 'Vérification de votre adresse email',
      FORGOTTEN_PWD: 'Mot de passe oublié ?',
      CHANGE_PWD: '',
      MY_ACCOUNT: 'Mon compte',
      MY_RESERVATIONS: 'Mes réservations',
      MY_INVOICES: 'Mes factures',
      RENT_BIKE: 'Réserver un vélo',
      RENT_STORAGE: 'Réserver une consigne',
      RENT_PARK: 'Réserver une consigne',
      RENEWAL: 'Renouvellement',
    },
    splash: {
      loading: 'Ne bougez pas, ça pédale fort..',
      loading_title: 'Ne bougez pas !',
      loading_description: 'Ca pédale fort !',
    },
    error: {
      base_title: 'Erreur',
      unimplementedCautionPaymentMean: "Ce moyen de paiement pour la caution n'est pas encore implémenté",
      default: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
      wrongBookingToken: "Votre réservation a expiré ou n'est plus valide",
      mandatoryField: 'Ce champ est obligatoire',
      tokenIsEmpty: 'Le token est vide',
      missingParameters: "Vous n'avez pas renseigné tous les champs obligatoires.",
      codeOrValidated:
        "Merci de vérifier les informations saisies. Attention : vous ne pouvez valider votre code qu'une seule fois.",
      alreadyAccount: 'Votre êtes déjà inscrit avec cette adresse email, merci de vous connecter !',
      wrongCredentials: 'Votre email ou votre mot de passe est incorrect',
      unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
      invalidOrExpiredToken: 'Votre session est expirée. Merci de vous reconnecter.',
      missingToken: 'Votre session est expirée. Merci de vous reconnecter.',
      emailNotValidated:
        "Vous n'avez pas encore validée votre adresse email. Vous pourrez ensuite vous connecter. Merci.",
      expiredBooking: 'Votre réservation est expirée. Nous vous invitons à recommencer le processus de réservation.',
      invalidBooking: "Votre réservation n'est pas valide",
      noAvailability:
        'Aucune disponibilité aux dates demandées. Veuillez changer vos dates de réservation ou renouveler votre réservation ultérieurement.Merci',
      maxResaCapacity: 'Vous avez atteint le maximum de réservations simultanées.',
      unknownUser: "Il semblerait que votre adresse email n'existe pas.",
      pleaseWait: 'Vous pouvez demander un nouveau mot de passe toutes les 10 minutes.',
      passwordTooShort: 'Votre mot de passe est trop court.',
      passwordsDontMatch: 'Le mot de passe et sa confirmation ne sont pas identiques.',
      wrongResetCode: 'Le lien que vous utilisez semble incorrect.',
      resetCodeExpired:
        'Le lien que vous utilisez est expiré, veuillez recommencer la procédure de mot de passe oublié.',
      resetCodeAlreadyUsed:
        'Vous avez déjà utilisé ce lien pour renouveler votre mot de passe, veuillez recommencer la procédure de mot de passe oublié.',
      wrongPayment: "Une erreur s'est produite avec le paiement",
      wrongFileFormat: "Votre document n'est pas au format png, pdf ou jpeg",
      deliveryPointClosed: "Veuillez choisir une heure dans les horaires d'ouverture du point de livraison !",
      mustBeAnAdult: 'Vous devez être majeur',
      invalidDate: 'Ce format de date est invalide',
    },
    home: {
      welcome: 'Bienvenue',
      on_tool: "sur l'outil de location de vélos et de consignes de la Communauté d'agglomération de",
      locality: 'Saint-Quentin-en-Yvelines',
    },
    sign_in: {
      please_sign_in: 'Merci de vous connecter',
      forgotten_pwd: 'Mot de passe oublié ?',
      sign_up: "S'inscrire",
      no_account: 'Pas encore de compte ?',
      forgotten_password: 'Un email vous a été envoyé pour réinitialiser votre mot de passe.',
      pwd_reset_success: 'Votre mot de passe a bien été réinitialisé',
      pwd_change_success: 'Votre mot de passe a bien été enregistré',
      log_in: 'Se connecter ?',
    },
    sign_up: {
      type_a_phone: 'Veuillez saisir au moins un numéro de téléphone',
      newsletter: 'Je souhaite recevoir des informations sur la Vélostation (nouveautés, actualités, ...)',
      already_an_account: 'Déjà un compte ?',
      please_sign_up: 'Merci de vous inscrire',
      nature: 'Nature',
      individual: 'Particulier',
      association: 'Association',
      company: 'Société',
      administration: 'Administration',
      civility: 'Civilité',
      mr: 'Monsieur',
      mrs: 'Madame',
      firm: 'Entreprise',
      firm_or_asso: 'Entreprise / Association (facultatif)',
      last_name: 'Nom',
      first_name: 'Prénom',
      email: 'Email',
      password: 'Mot de passe',
      birthdate: 'Date de naissance',
      origin_country: "Pays d'origine",
      address: 'Adresse',
      full_address: 'Adresse complémentaire (facultatif)',
      zip: 'Code Postal',
      city: 'Ville',
      country: 'Pays',
      phone: 'Téléphone fixe',
      mobile_phone: 'Téléphone portable',
      email_error: "Le format d'email est incorrect",
      password_error: 'Min. 8 caractères et uniquement des lettres avec au moins une majuscule',
      password_requirements:
        'Votre mot de passe doit contenir min. 8 caractères et uniquement des lettres avec au moins une majuscule',
      sign_up_success:
        'Merci pour votre inscription. Nous allons vous envoyer un email pour vérifier votre adresse email, vous pourrez ensuite vous connecter !',
      copy_password: 'Mot de passe (confirmation)',
      my_pwd: 'Mon mot de passe',
      change_pwd_description:
        'Pour des raisons de sécurité, suite à votre première connexion depuis votre passage en agence, vous devez changer votre mot de passe reçu par email.',
    },
    contact: {
      title: 'Contact',
      contact_us: 'Contactez-nous',
      let_your_contact:
        "N'hésitez pas à nous contacter et nous laisser vos coordonnées pour que nous puissions vous répondre !",
      success: 'Votre demande nous a bien été transmise !',
      input_label: 'Expéditeur du message :',
    },
    forgotten_pwd: {
      title: 'Mot de passe oublié',
      description: 'Pas de panique, ça arrive à tout le monde !',
      button: 'Nouveau mot de passe',
    },
    mail_validation: {
      title: 'Vérification de votre adresse email',
      please_verify: 'Merci de saisir votre adresse email et le code reçu par email',
      code: 'Code',
      verify: 'Vérifier',
      has_no_account: 'Pas encore de compte ?',
      forgotten_pwd: 'Mot de passe oublié ?',
      email_validated: 'Votre adresse est validée, vous pouvez maintenant vous connecter !',
    },
    my_account: {
      update_infos: 'Modifier mes informations',
      infos_updated: 'Vos informations ont bien été mises à jour !',
      you_can_modify: 'Vous pouvez modifier vos informations personnelles ici',
    },
    my_reservations: {
      no_contracts: 'Aucun contrat',
      consult_here: 'Consultez ici vos réservations en cours et passées.',
      current_reservations: 'Réservations en cours ({{currentResa}})',
      past_reservations: 'Réservations passées ({{pastResa}})',
    },
    my_invoices: {
      price_type: 'TTC',
      no_invoice: 'Aucune facture en cours',
      header_description: 'Consultez ici toutes vos factures',
      tab_title: 'Facture',
    },
    workshop: {
      title: "L'Atelier",
      meet_at_workshop: "Rendez-vous à l'atelier.",
      check_up:
        "Votre vélo a besoin d'un check-up ? Nous vous proposons un service de maintenance incluant réglages et répération courantes. Vous y touverez également des conseils et des réponses à toutes vos questions sur l'entretien de votre vélo.",
      opening_times: "Horaires d'ouverture",
      opening_hours: 'lundi au vendredi : 7h30 - 12h et 14h - 19h30',
      saturday: 'samedi : 10h - 13h',
      street: '1 ter Place Charles de Gaulle',
      city: '78180 Montigny-le-Bretonneux',
      tel: 'Tel: {{ item }}',
      mail: 'Email: {{ item }}',
    },
    resabike: {
      price_ttc: 'Les prix indiqués sont TTC',
      book_a_bike: 'Réserver un vélo',
      free_price: 'Gratuit',
      free_total: 'Rien à régler',
      rental_prices: 'Les tarifs de location pour un velo ',
      standard: 'standard',
      choose_duration: 'Choisissez une durée de location et un tarif :',
      back: 'Revenir à la page précédente',
      book: 'RÉSERVER',
      description: 'Nous vous proposons différents types de vélos.',
      select_title: 'Choisissez votre point de location :',
      select_holder: 'Veuillez sélectionner un point de location',
      bike_number: '{{bikeNumber}} vélos',
      park_number: '{{bikeNumber}} consignes',
      want_pay_caution_with_cheque:
        'Si vous souhaitez régler votre caution par chèque, il faudra venir avec le jour du rendez-vous.',
      caution_section_title: 'Enregistrement de la caution',
      caution_is_paid: 'La caution a bien été enregistrée',
      caution_is_paid_title: 'Caution enregistrée',
      caution_section_cta: 'Enregistrer la caution',
      bike_disponibility:
        " {{bikeType}}  disponible aujourd'hui, pour une durée de location d'un jour - peut varier en fonction de votre durée de location.",
      secured_park: 'consigne sécurisée',
      ttc_day: 'TTC / jour',
      other_price: '+ autres tarifs',
      form_start_date: 'Date de début',
      form_end_date: 'Date de fin',
      you_choose_a: 'Vous avez choisi un',
      you_choose_a_park: 'Vous avez choisi une',
      tenant_info: '<b>Cette location est attribuée à :</b> {{tenant}}',
      iban: 'IBAN',
      bic: 'BIC',
      invalid_iban: 'Votre IBAN est invalide',
      invalid_bic: 'Votre BIC est invalide',
      duration: 'Durée',
      min: 'minimum',
      max: 'maximum',
      electric: 'You have an electric bike ?',
      of_rental: 'de location pour le tarif choisi :',
      id_card: "Justificatif d'identité (carte d'identité recto/verso, permis de conduire, ...)",
      RIB: "Relevé d'Identité Bancaire (pour la pénalité non encaissée)",
      if_you_dont_have:
        "Si vous n'avez pas ces justificatifs à portée de main, vous pourrez les apporter directement en agence.",
      please_give_IBAN: 'Merci de renseigner votre IBAN et votre BIC pour faciliter votre réservation :',
      proccess_payment: 'Procéder au paiement',
      your_data_wont_saved: ' (attention, vos données ne seront pas sauvegardées)',
      you_choose: 'Vous avez choisi :',
      rental_is_to: 'Cette location sera attribuée à : ',
      total_cost: 'Coût total : ',
      please_wait: 'Veuillez patienter...',
      wait_validation: 'Validation de votre paiement en cours...',
      congratulation: 'Félicitations !',
      pay: 'Payer',
      payWithAmount: 'Payer maintenant {{price}}',
      recap_choice: 'Vous avez choisi le modèle : ',
      recap_security_deposit: 'Montant du dépôt de garantie : ',
      recap_location: 'Cette location sera attribuée à : ',
      recap_total: 'Total',
      recap_title: 'Récapitulatif',
      recap_caution_title: 'Caution non-encaissée',
      we_have_several_bikes: 'Nous vous proposons différents types de vélos.',
      we_have_several_parks: 'Nous vous proposons différents types de consignes.',
      payment_invalid: 'Une erreur est survenue lors du paiement',
      form_token_expired: "La transaction a expiré ou le formulaire n'est plus disponible",
      payment_failed: 'Le paiement a échoué, veuillez réessayer.',
      payment_failed_title: 'Paiement refusé',
      security_deposit_choose: ' + Dépôt de garantie',
    },
    resa_storage: {
      title_header: 'Réserver une consigne',
      transport_card: {
        first_descr: 'Le pass Navigo en cours de validité est',
        second_descr: 'OBLIGATOIRE',
        third_descr: 'pour accéder à nos consignes sécurisées !! Il est composé de 10 chiffres',
        fourth_descr: '(sans la lettre)',
        fifth_descr: 'et est imprimé sur votre pass.',
      },
      transport_card_placeholder: 'Numéro de pass Navigo',
      transport_card_upload: 'Justificatif d’achat de votre pass Navigo',
      btn_pay: 'Procéder au paiement',
      invalid_transport_card: 'Votre numéro de carte est invalide',
      modal_transport_card: {
        link: 'Où est le numéro ?',
        title: 'Où trouver le numéro demandé ?',
        close: 'Fermer',
        first_descr: 'Indiquez le numéro',
        second_descr: 'SANS ESPACE ET SANS LA LETTRE',
        third_descr: 'de votre carte Navigo (voir ci-dessus)',
        fourth_descr: 'Exemple : pour',
        fifth_descr: '0123456789',
      },
    },
    paymentSchedule: {
      title: 'Votre échéancier',
      advice: '1 mois après le début de votre location, vous serez débité des montants suivants mensuellement',
    },
    payment: {
      title: 'Paiement de la réservation',
      advice: 'Vous ne pouvez payer que par carte bancaire.',
      total_title: 'A régler',
      total_is_paid_title: 'Réglé',
      security_deposit_wording: 'et du dépôt de garantie encaissé : {{ amountSecurityDeposit }} {{ currency }} TTC',
      renewal_title: 'Paiement du renouvellement',
      success: 'Votre paiement a été validé !',
      success_deposit: 'Votre moyen de paiement de la caution a été enregistré avec succès.',
      caution_title: 'Caution à régler : {{ caution }} : {{ price }} {{ currency }}',
      booking_title: 'Règlement du {{ article }} : {{ price }} {{ currency }} TTC',
      caution_wording:
        'Pour votre réservation de {{ article }} une caution de {{ price }} {{ currency }} TTC est demandée',
      caution_wording_2:
        'Pour votre réservation, une caution {{ caution }} de {{ price }} {{ currency }} TTC est demandée',
      choose: 'Veuillez choisir un moyen de règlement pour le règlement de la réservation',
      agency_payment: 'Nous vous attendons en agence pour le règlement de votre {{ type }} ',
      pay_booking: 'Procéder au règlement',
      finish_booking: 'Voir ma confirmation',
      processing: 'Paiement en cours de validation',
      type: {
        booking: 'réservation',
        deposit: 'caution',
      },
      caution_mean: {
        cb: 'Caution en CB',
        agency: 'Caution en agence',
        sepa: 'Caution en SEPA',
      },
      mean: {
        cb: 'Paiement en CB',
        agency: 'Paiement en agence',
        sepamult: 'Prélèvement SEPA mensuel',
        cbmult: 'Paiement en {{reccurence}} fois sans frais',
        sepa: 'Paiement en SEPA',
      },
    },
    form: {
      notice: "Les champs marqués d'une astérisque sont obligatoires.",
      errors: {
        caution: {
          required: 'Vous devez choisir une des deux options',
        },
      },
    },
    languages: {
      fr: 'FR 🇫🇷',
      en: 'EN 🇬🇧',
    },
    stripePage: {
      cardNumber: 'Numéro de la carte',
      expirationDate: 'Date d’expiration',
      cvc: 'CVC',
    },
  },
};
