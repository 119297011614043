import React from 'react';

type BaseNavbarProps = {
  leftSlot: JSX.Element;
  rightSlot?: JSX.Element;
};

function BaseNavbar({ leftSlot, rightSlot }: BaseNavbarProps) {
  return (
    <header
      className={
        'bg-navbar-background fixed md:absolute top-0 inset-x-0 h-header-sm md:h-header-md w-full z-50 flex items-center'
      }
    >
      <div className={'container mx-auto flex justify-between items-center'}>
        {leftSlot}
        {rightSlot}
      </div>
    </header>
  );
}

export default BaseNavbar;
