import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectRoutes } from 'internal/selectors/remoteConfigSelectors';
import { RemoteRouteType } from 'internal/types/remoteConfig';
import routes, { RouteType } from 'router/routes';

enum RoutesMap {
  HOME = 'home',
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  MAIL_VALIDATION = 'mailValidation',
  CONTACT = 'contact',
  FORGOTTEN_PWD = 'forgottenPassword',
  CHANGE_PWD = 'changePwd',
  MY_ACCOUNT = 'myAccount',
  MY_RESERVATIONS = 'myReservations',
  MY_INVOICES = 'myInvoices',
  WORKSHOP = 'workshop',
  SIGN_OUT = 'signOut',
  RENT_BIKE = 'rentBike',
  RENT_STORAGE = 'rentStorage',
  RENT_PARK = 'rentPark',
  INSURANCE = 'insurance',
  REPAIR_AND_OVERHAUL = 'repairAndOverhaul',
  RENEWAL = 'renewal',
  ACCESSOIRES = 'resaBikeAccessories',
  LISTE_ATTENTE = 'waitingList',
}

const useIsAuthorize = (path: string, routeName?: string) => {
  const configRoutes = useSelector(selectRoutes);

  return useMemo(() => {
    if (routeName) {
      return getIsAuthorizeWithName(configRoutes, routeName);
    }

    return isAuthorize(configRoutes, path);
  }, [configRoutes, path, routeName]);
};

const FLATTEN_ALL_ROUTES = Object.entries(routes).reduce((allRoutes, [nameRoute, route]) => {
  if ('children' in route) {
    allRoutes = { ...route.children, ...allRoutes };
  }
  return { [nameRoute]: route, ...allRoutes };
}, {} as Record<string, RouteType>);

export const isAuthorize = (configRoutes: RemoteRouteType | undefined, path: string) => {
  if (path[0] !== '/') return true;

  const route = Object.entries(FLATTEN_ALL_ROUTES).find(([, route]) => route.path === path);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const route = Object.entries(FLATTEN_ALL_ROUTES).find(([, { children, ...route }]) => matchRoutes([route], path));
  if (route) {
    const index = Object.values(RoutesMap).indexOf(route[0] as RoutesMap);
    return configRoutes && index !== -1 ? configRoutes[Object.keys(RoutesMap)[index]].isEnabled : true;
  }

  return true;
};

export function getIsAuthorizeWithName(configRoutes: RemoteRouteType | undefined, routeName: string) {
  const index = Object.values(RoutesMap).indexOf(routeName as RoutesMap);
  return configRoutes && index !== -1 ? configRoutes[Object.keys(RoutesMap)[index]].isEnabled : true;
}

export default useIsAuthorize;
