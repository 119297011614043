import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { Api } from '../api';
import { SERVICE_KEYS } from '../ioc/keys';
import { ReservationDetailsType } from '../pages/home/types/reservation';

interface BookingRepositoryInterface {
  getInfo(token: string): Promise<AxiosResponse<ReservationDetailsType>>;

  process(bodyParams: Record<string, unknown>): Promise<AxiosResponse<{ redir_to: string }>>;
}

@injectable()
export class BookingRepository implements BookingRepositoryInterface {
  @inject(SERVICE_KEYS.API_INSTANCE)
  private _apiInstance: Api | undefined;

  getInfo = async (token: string): Promise<AxiosResponse<ReservationDetailsType>> => {
    if (!this._apiInstance) throw new Error('api dependency is not init');
    return await this._apiInstance.instance.get<ReservationDetailsType>('booking/info', { params: { token } });
  };

  process = (bodyParams: Record<string, unknown>): Promise<AxiosResponse<{ redir_to: string }>> => {
    if (!this._apiInstance) throw new Error('api dependency is not init');
    return this._apiInstance.instance.post<{ redir_to: string }>('booking/process', bodyParams);
  };
}
