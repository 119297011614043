import axios from 'axios';
import { API_KEY, BASE_URL, TIMEOUT } from '../internal/constants/api';

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
  headers: {
    ['X-Veloclik-Apikey']: API_KEY,
  },
});

export default instance;
