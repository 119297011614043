import RequireAuthentication from 'hoc/RequireAuthentication';
import RequireRouteEnable from 'hoc/RequireRouteEnable';
import PageNotFound from 'internal/components/PageNotFound';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from 'router/routes';
import PageError from '../internal/components/PageError';
import AppLayout from '../Layouts/AppLayout';
import DefaultLayout from '../Layouts/DefaultLayout';

// TODO: add hide function etc like other router

const Router = React.memo(() => {
  const routesWithAppLayout = Object.entries(routes).filter(
    ([, route]) => !route.layout || route.layout.displayName === 'AppLayout'
  );

  return (
    // <Suspense fallback={<div>...chargement</div>}>
    <Routes>
      <Route element={<AppLayout />}>
        {routesWithAppLayout.map(([routeName, { element, path, privateRoute, children }]) => {
          if (!element) throw new Error('component is not defined');

          const Component = !privateRoute
            ? RequireRouteEnable(element, routeName)
            : RequireRouteEnable(RequireAuthentication(element), path);

          return children ? (
            <Route key={path} path={path} element={<Component />}>
              {Object.entries(children).map(([, subRoute]) => {
                if (!subRoute.element) throw new Error('component is not defined');
                const SubRouteComponent = subRoute.element;

                return (
                  <Route
                    key={subRoute.path}
                    path={subRoute.path}
                    index={subRoute.path === '' || subRoute.path === '/'}
                    element={<SubRouteComponent />}
                  />
                );
              })}
            </Route>
          ) : (
            <Route key={path} path={path} index={path === '/'} element={<Component />} />
          );
        })}
        <Route path={'/error'} element={<PageError />} />
      </Route>
      <Route element={<DefaultLayout />}>
        <Route path={'*'} element={<PageNotFound />} />
        <Route path={'/404'} element={<PageNotFound />} />
      </Route>
    </Routes>
    // </Suspense>
  );
});

export default Router;
