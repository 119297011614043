import React, { useState } from 'react';
import packageInfos from '../../../package.json';
import { ReactComponent as Logo } from 'assets/svg/vdi-logo.svg';
import { ReactComponent as ChevronIcon } from 'assets/svg/chevron-footer.svg';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RouteType } from 'router/routes';
import { selectRemoteTheme } from 'internal/selectors/remoteConfigSelectors';
import useIsAuthenticated from 'hook/auth/useIsAuthenticated';
import Link from '../Link/Link';
import useGetFooterLinks from '../../const/footerLinks';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type FooterColLinks = {
  label: string;
  links: {
    label: string;
    to: RouteType;
  }[];
};

function ColLinks({ col }: { col: FooterColLinks }): JSX.Element {
  return (
    <div className={'flex flex-col gap-y-8 md:gap-y-4 md:text-xs'}>
      <span className={'text-primary-200 font-bold'}>{col.label}</span>
      {col.links.map((element, index) => {
        return (
          <Link
            key={element.to.path + index}
            className={'text-black hover:decoration-black hover:underline'}
            {...element}
          >
            {element.label}
          </Link>
        );
      })}
    </div>
  );
}

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const theme = useSelector(selectRemoteTheme);
  const FOOTER_LINKS = useGetFooterLinks();

  const onClick = () => setIsOpen(!isOpen);

  const bottomFooterContentClassName = classNames(
    isOpen ? 'flex' : 'hidden',
    'md:flex flex-wrap items-center justify-between justify-self-end'
  );

  return (
    <footer>
      {isAuthenticated && (
        <section className={'bg-white py-8 md:h-[219px]'}>
          <div className={'container md:flex md:mx-auto'}>
            <div className={'border-b md:border-none border-gray-200 pb-8 mb-8 md:mb-0 md:w-[260px] md:mr-40'}>
              <div className={'mb-4'}>
                <LazyLoadImage className={'block max-h-[53px]'} src={theme?.footerLogo} alt={'collectivity-logo'} />
              </div>
              {/*<p className={'text-sm text-black mb-2 leading-normal md:text-[10px]'}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut sem eu ligula volutpat cursus. In hac
                habitasse platea dictumst. Sed fermentum, enim feugiat pellentesque vulputate, erat tellus sodales nisi.
              </p>*/}
              <Link className={'font-bold mb-2 md:text-xs'} inContent to={{ path: 'https://velogik.com' }}>
                velogik.com
              </Link>
              <span className={'block text-[#aaa] md:text-xs'}>V.{packageInfos.version}</span>
            </div>
            <div className={'flex flex-col gap-y-16 mb-16 md:flex-row md:gap-x-56'}>
              {FOOTER_LINKS.map((footerCol, index) => (
                <ColLinks key={'footer-col-links' + index} col={footerCol} />
              ))}
            </div>
          </div>
        </section>
      )}
      <section
        className={'min-h-[86px] container mx-auto flex flex-col md:flex-row md:gap-8 md:items-center justify-between'}
      >
        <div className={'flex md:flex-initial h-[86px] md:h-auto items-center justify-between'}>
          <Logo width={62} />
          <button
            className={classNames(
              'md:hidden transition-transform duration-150 ease-in-out',
              { 'rotate-180': isOpen },
              isOpen ? 'text-primary-100' : 'text-white'
            )}
            onClick={onClick}
          >
            <ChevronIcon width={16} />
          </button>
        </div>
        <div className={bottomFooterContentClassName}>
          {/*<p className={'text-sm md:text-[10px] w-full leading-normal flex-auto md:flex-initial md:w-[500px]'}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut sem eu ligula volutpat cursus. In hac
            habitasse platea dictumst. Sed fermentum, enim feugiat pellentesque vulputate, erat tellus sodales nisi.
          </p>*/}
          <div className={'flex items-center justify-between flex-auto md:flex-col md:flex-initial mt-4 mb-8 md:my-0'}>
            <Link className={'md:text-sm font-bold'} inContent to={{ path: 'https://velogik.com' }}>
              velogik.com
            </Link>
            <span className={'md:text-[10px]'}>© Vélogik 2022</span>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
